export default {
  init() {

    // Anchors
    $('a').on('click', function(e) {
      let section = $(this).data('section');

      // Scroll to element if set
      if (typeof section !== 'undefined') {
        e.preventDefault();

        $('html, body').animate({
          scrollTop: $('.' + section).offset().top - 50
        }, 500);
      }
    });
    
    // Tabs
    $('.tabs a').on('click', function(e) {
      if ($(this).attr('href').length == 0 || $(this).attr('href') == '#') {
        e.preventDefault();
        let $this = $(this);
        let index = $this.index();
  
        if (!$this.hasClass('active')) {
          $('.tabs a').removeClass('active');
          $('.tab-content').removeClass('active');
  
          $this.addClass('active');
          $('[data-tab="' + index + '"].tab-content').addClass('active');       
        }
      }
    
    });

    // Quiz
    $('.slider').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      touchMove: false,
      arrows: false
    });

    $('.slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
      let items = $('.slick-slide').length;

      if (items == currentSlide + 1) {
        $('.section-11').find('.next').hide();
        $('.section-11').find('.last').show();
      } else {
        $('.section-11').find('.next').show();
        $('.section-11').find('.last').hide();
      }
    });

    $('.section-11 .next').on('click', function(e) {
      e.preventDefault();
      $('.message-container').hide();

      if ($(this).hasClass('last')) {
        let calculatedValue = Calculate();
        let $res;

        $.each($('.section-12 .result'), function() {
          let $this = $(this);
          let points = $this.data('p').split('-');
          if ( calculatedValue >= points[0] && calculatedValue <= points[1] ) {
            $res = $this;
            let ind = $res.index();
            // document.querySelector('meta[property="og:title"]').setAttribute("content", $this.find('.s-heading').text());
            // document.querySelector('meta[property="og:description"]').setAttribute("content", $this.find('.s-text').text());
            let url = window.location.origin;
            if (ind == 0) {
              url = 'https://vsaka-stopinja-steje.osvoji-nagrado.si/cas-je-za-spremembe/';
            } 
            if (ind == 1) {
              url = 'https://vsaka-stopinja-steje.osvoji-nagrado.si/ste-na-dobri-poti/';
            } 
            if (ind == 2) {
              url = 'https://vsaka-stopinja-steje.osvoji-nagrado.si/le-tako-naprej/';
            } 
            $('.share-btn').attr('href', 'https://www.facebook.com/sharer/sharer.php?display=page&u=' + url);
          }
        });
        
        $('.section-11').remove();
        $('.section-12').show();
        $res.show();
      }

      if (ValueSet())
        $('.slider').slick('slickNext');
      else
        $('.message-container').show();
    });

    $('.section-11 .prev').on('click', function(e) {
      e.preventDefault();
      $('.message-container').hide();
      $('.slider').slick('slickPrev');
    });

    function ValueSet() {
      let isChecked = false;
      let $currentSlide = $('.slick-active');
      let index = $currentSlide.index();

      if ($('input[name=q' + (index + 1) + ']:checked').length > 0) {
        isChecked = true;
      }

      return isChecked;
    }

    function Calculate() {
      let val = 0;

      $.each($('.slick-slide'), function(index, value) {
        val += parseInt( $('input[name="q' + (index + 1) + '"]:checked').val() );
      });

      return val;
    }

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
